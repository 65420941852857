.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --clr-red: #c51858;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: #23283e;
  --clr-bg-alt: #2a2f4c;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #90a0d9;
  --clr-red: #f36060;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: '';
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline.btn--red {
  color: var(--clr-red);
  border-color: var(--clr-red);
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: '';
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline.btn--red:before {
  background-color: var(--clr-red);
}

.btn--outline:hover:before,
.btn--outline:focus:before {
  right: 0;
}

.btn--outline > * {
  background: transparent;
}

.btn > small {
  font-size: .75rem;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-tabs {
	border-bottom: 1px solid var(--clr-fg-alt);
}

.nav-link {
	display: block;
	padding: .5rem 1rem;
	color: var(--clr-fg);
	text-decoration: none;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.nav-tabs .nav-link {
	margin-bottom: -1px;
	background: 0 0;
	border: 1px solid transparent;
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	border-color: var(--clr-fg-alt);
	isolation: isolate;
}

.nav-link:focus, .nav-link:hover {
	color: var(--clr-fg);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	color: var(--clr-bg);
	background-color: var(--clr-fg-alt);
	border-color: var(--clr-fg);
}

.tab-content > .tab-pane {
	display: none;
}

.fade {
	transition: opacity .15s linear;
}

.fade:not(.show) {
	opacity: 0;
}

.tab-content > .active {
	display: block;
}

.layer-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-fg);  
}

.layer-modal h6, .layer-modal p, .layer-modal a, .layer-modal button, .layer-modal div, .layer-modal span {
  color: var(--clr-fg-alt) !important;
}

.layer-box {
  z-index: 3;
  min-width: 50%;
  padding: 30px;
  background-color: var(--clr-bg);
}

.layer-modal.auto-width .layer-box {
  width: auto;
  min-width: 0;
}

input[type="text"], input[type="number"] {
  color: var(--clr-primary);
  border: 1px solid var(--clr-primary);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5em 1em;
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}
