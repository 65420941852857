* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
  width: 100%;
  
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

section h2,
section h3 {
  margin-bottom: 0.75rem;
}

section p + h3 {
  margin-top: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

section img {
  max-width: 100%;
  border-radius: 1rem;
}

section h2 > img {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

p {
  line-height: 1.5;
  margin-bottom: 1rem;
}

p:last-child {
  margin-bottom: 0;
}

p a {
  color: var(--clr-fg-alt);
  font-weight: 500;
}

#root {
  height: 100%;
}

#top {
  min-height: 100%;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}
