.nav__list {
  margin-right: 1.5em;
  display: flex;
  align-items: center;
}
.nav__list-item {
  margin-left: .5em;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.nav__list-item::before {
  content: '|';
  font-size: 1.1rem;
  font-weight: normal;
  vertical-align: middle;
  margin-right: .5rem;
}

.nav__list-item:first-child::before {
  content: '';
  display: none;
} 

.nav__list-item:last-child {
  margin-left: 1.5rem;
} 

.nav__list-item:last-child::before {
  content: '';
  display: none;
} 

.app .nav__hamburger {
  display: none;
}

.nav__list-item.nav-flex {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.nav__theme {
  margin-top: 0.4em;
  z-index: 2;
}

.nav__list-item span.left-line {
  margin-left: 1rem;
  border-left: 1px solid var(--clr-fg-alt);
  padding-left: 1rem;
  vertical-align: middle;
}

.nav__list-item > button > * {
  vertical-align: middle;
}

@media (max-width: 1025px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .nav__list-item::before {
    display: none;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  height: 80%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
