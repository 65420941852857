.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.section__content.nft-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.section__content.nft-section.centered {
  justify-content: center;
}

.nft-card {
  display: block;
  width: 100%;
  max-width: 200px;
  padding: .5rem;
}

.nft-card > .nft-card__image > img {
  max-width: 100%;
  border-radius: 1rem;
}

.nft-card > .nft-card__content > h3 {
  font-size: 1.1rem;
}

.nft-card-balances {
  text-align: right;
  margin: 10px 0;
}

.active-set > td:first-child {
  border-left: solid 6px #009999;
}