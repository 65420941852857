.nft-card.hatchable:hover .nft-card__image {
  position: relative;
}

.nft-card.hatchable.selected .nft-card__image {
  position: relative;
}

.nft-card.hatchable:hover .nft-card__image::before {
  content: 'SELECT';
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: .5rem;
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  pointer-events: none;
}

.nft-card.hatchable.selected:hover .nft-card__image::before {
  content: 'REMOVE';
}

.nft-card__content.centered {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  text-align: center;
}

.nft-card__content.centered > button {
  width: auto;
  flex-basis: 100%;
}

.mating-selection .nft-card.hatchable.selected {
  opacity: 0.5;
}

.mating-selection .nft-card.hatchable.selected {
  outline: var(--clr-bg-alt) solid 2px;
}

.section__content.mating {
  align-items: center;
}

